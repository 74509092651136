/* Adaptive styles */
/* Desktop */
@media (max-width: 1199px) {
  .container{
    width: 970px;
  }
  .hamb{
    display: block;
    width: 50px;
    height: 50px;
    margin-left: auto;
    padding: 10px;
    margin-right: 20px;
    z-index: 2;
    position: relative;
    span{
      background: #000;
      height: 3px;
      display: block;
      margin: 5px 0px;
      transition: all 0.3s;
    }
    &.open{
      span:nth-child(2){
        display: none;
      }
      span:first-child{
        transform: rotate(45deg);
        top: 8px;
        position: relative;
      }
      span:last-child{
        transform: rotate(-45deg);
      }
    }
  }
  .menu{
    position: absolute;
    top: calc(100% - 50px);
    right: 0px;
    background: #fff;
    padding: 70px 30px 30px;
    flex-direction: column;
    z-index: 1;
    border-radius: 0 0 50px 50px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    &.open{
      opacity: 1;
      pointer-events: all;
    }
    &__link{
      display: block;
      padding: 5px 0;
    }
  }
  .slide__info{
    margin-left: 50px;
  }
  .slide__img{
    max-width: 100%;
    width: 550px;
    flex-shrink: 0;
    img{
      max-width: 100%;
    }
  }
  .news{
    width: 293px;
  }
  .news-block--inner .news {
    width: calc(33% - 30px);
  }
  .news-block--projects{
    .news{
      width: calc(25% - 30px);
    }
  }
}


/* Tablet */
@media (max-width: 991px)  {
  .container{
    width: 750px;
  }
  .slide{
    position: relative;
    flex-direction: column;
  }
  .slide__info {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 0;
    position: relative;
    z-index: 1;
  }
  .slide__img{
    width: 400px;
    max-width: 100%;
  }
  .news-block{
    flex-wrap: wrap;
    justify-content: center;
  }
  .news{
    width: calc(50% - 30px);
  }
  .about-item{
    margin: 0 10px 30px;
  }
  .footer__col:first-child{
    width: 33%;
  }
  .pers__ava{
    width: 160px;
    height: 160px;
  }
  .news-block--inner .news {
    width: calc(50% - 30px);
  }
  .news-block--projects{
    .news{
      width: calc(33% - 30px);
    }
  }
  .map-row{
    flex-direction: column;
  }
  .coop__left{
    width: 200px;
  }
}



/* Phone */
@media (max-width: 767px){
  .container{
    width: 100%;
  }
  .partner .slick-dots{
    padding: 0 50px;
  }
  .partner-block .slick-nav__prev,
  .partner-block .slick-nav__next{
    left: 0;
    top: auto;
    bottom: -65px;
    margin: 0;
  }
  .partner-block .slick-nav__next{
    left: auto;
    right: 0;
  }
  .about{
    flex-wrap: wrap;
  }
  .header{
    flex-direction: column;
    align-items: center;
  }
  .search-header{
    margin-top: 10px;
  }
  .slider{
    padding-top: 270px;
    height: 830px;
  }
  .slide__title{
    font-size: 25px;
  }
  .slide__info{
    margin-bottom: 20px;
  }
  .news{
    width: 360px;
    max-width: 100%;
  }
  .footer{
    flex-wrap: wrap;
  }
  .footer__col:first-child{
    width: 270px;
  }
  .footer__col:last-child{
    width: 100%;
    margin-top: 20px;
  }
  .logo{
    position: relative;
    z-index: 2;
  }
  .block__title{
    font-size: 25px;
  }
  .block .more{
    font-size: 16px;
  }
  .grey-bg{
    padding: 50px 0;
  }
  .section{
    padding: 50px 0 20px;
  }
  .scheme__row{
    flex-wrap: wrap;
    &--first:after {
      width: 50.4%;
    }
  }
  .pers{
    width: 46%;
  }
  .header-bg {
    height: 175px;
    background-size: cover;
  }
  .tabs-title{
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .scheme__title{
    font-size: 18px;
  }
  .about-b__title{
    width: 200px;
    margin-right: 20px;
  }
  .contacts-b{
    flex-direction: column;
  }
  .contacts-b__col,
  .contacts-b__col:first-child{
    width: 100%;
    margin-right: 0;
  }
  .contacts-b__col:first-child{
    margin-bottom: 30px;
  }
  .contacts-b h2{
    margin-bottom: 20px;
  }

  .news-block--projects{
    .news{
      width: calc(50% - 30px);
    }
  }
  .coop{
    flex-direction: column;
    h1{
      font-size: 25px;
    }
    h2{
      font-size: 18px;
    }
  }
  .coop__left{
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .typograph__flex{
    flex-direction: column;
    margin-top: 15px;
    align-items: flex-start;
    .cash-news{
      margin-top: 20px;
    }
  }
  .typograph__cols{
    flex-direction: column;
  }
  .typograph__col{
    width: auto !important;
    margin-bottom: 0;
  }
}



@media (max-width: 479px){
  .logo{
    width: 200px;
    img{
      width: 100%;
    }
  }
  .footer{
    flex-direction: column;
  }
  .menu-footer{
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .footer__col:first-child{
    width: 100%;
  }
  .about-b__item{
    flex-direction: column;
  }
  .about-b__title{
    margin-right: 0;
    margin-bottom: 10px;
  }
  .item-style{
    flex-direction: column;
  }
  .item-style__content{
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .item-style__text{
    margin-bottom: 5px;
  }
  .news-block--inner .news {
    width: 290px;
  }
  .news-block--projects{
    .news{
      width: 290px;
    }
  }
  .maps h1{
    font-size: 25px;
  }
  .maps h2{
    font-size: 20px;
  }
}
