@import "normalize";
@import 'fontsMixin';
@import 'var';
//@import 'sprite';
@import 'slick';
@import 'jquery.fancybox';
@import 'aos';

// Подключение шрифтов
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

// @include sprite($bitcoin-logo);

*{
  box-sizing: border-box;
}

html,
body{
  height: 100%;
}

body{
  color: $black;
  background: $white;
  font-family: 'Montserrat', sans-serif;
  min-width: 375px;
}

input,
button,
textarea{
  font-family: 'Montserrat', sans-serif;
}

.page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &__wrap{
    flex-grow: 1;
  }
}

.container{
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

.main-page{
  header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

header{
  height: 230px;
  position: relative;
  z-index: 1;
}
.header-bg{
  background: url('../img/header-bg.jpg') center top no-repeat;
  height: 130px;
}
.header{
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.socials{
  display: flex;
  flex-wrap: wrap;
  &__link{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    margin-left: 15px;
    color: #1db8d3;
    background: rgba(#fff, 0.5);
    flex-shrink: 0;
    &:first-child{
      margin-left: 0;
    }
    &:hover{
      background: #fff;
    }
    .icon{
      display: block;
      max-width: 20px;
      max-height: 20px;
      margin: auto;
      fill: currentColor;
    }
  }
}
.search-header{
  border-radius: 20px;
  background-color: #d3f4fb;
  display: flex;
  &__input{
    border: none;
    background: none;
    color: #222222;
    font-size: 13px;
    font-weight: 400;
    width: 220px;
    padding: 0 10px 0 20px;
    outline: none;
  }
  &__button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eace8e;
    border: none;
    display: flex;
    color: #fff;
    outline: none;
    cursor: pointer;
    .icon{
      display: block;
      width: 16px;
      height: 16px;
      margin: auto;
      fill: currentColor;
    }
    &:hover{
      color: #1db8d3;
    }
  }
}
.main-header{
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: #ffffff;
  padding: 20px 10px 20px 30px;
  position: relative;
}
.hamb{
  display: none;
}
.menu{
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  &__li{
    display: block;
  }
  &__link{
    color: #222222;
    font-size: 14px;
    text-decoration: none;
    margin-left: 10px;
    &:hover{
      color: #1db8d3;
    }
  }
}

.slider{
  background: url('../img/slider-bg.jpg') top center no-repeat;
  background-size: cover;
  height: 800px;
  padding-top: 240px;
  .container{
    position: relative;
  }
  .slick-nav__prev,
  .slick-nav__next{
    color: #fff;
    display: flex;
    &:hover{
      background: #fff;
      color: #eace8e;
    }
  }
  .slick-nav__next{
    left: auto;
    right: 0;
    transform: scaleX(-1);
  }
}
.slide{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 560px;
  &__info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: auto;
    margin-top: 70px;
    margin-left: 100px;
  }
  &__img{
    img{
      display: block;
    }
  }
  &__title{
    color: #ffffff;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 23px;
  }
  &__text{
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 140%;
  }
  &__more{
    border-radius: 30px;
    background-color: #eace8e;
    height: 60px;
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    padding: 0 40px;
    line-height: 58px;
    &:hover{
      background: #1db8d3;
    }
  }
}

.grey-bg{
  background-color: #eefcff;
  padding: 100px 0;
}
.block{
  &__top{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 65px;
  }
  &__title{
    margin: 0;
    color: #222222;
    font-size: 35px;
    font-weight: 700;
  }
  .more{
    margin-left: 20px;
    color: #65d5e3;
    font-size: 20px;
    font-weight: 400;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
      color: #eace8e;
    }
  }
}

.news{
  border-radius: 20px;
  border: 1px solid #d3f4fb;
  background-color: #ffffff;
  width: 360px;
  margin: 0 15px 30px;
  &--sheme{
    .news__title{
      color: #32b3dd;
    }
    .news__text{
      margin: 0;
    }
  }
  &-block{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    &--sheme{
      margin-top: 40px;
    }
  }
  &__img{
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    img{
      display: block;
    }
  }
  &__content{
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  &__date{
    font-size: 14px;
    color: #999;
    margin-bottom: 3px;
  }
  &__title{
    color: #222222;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    &:hover{
      color: #1db8d3;
    }
  }
  &__text{
    color: #222222;
    font-size: 14px;
    margin: 15px 0 20px;
    line-height: 20px;
  }
  &__more{
    border-radius: 20px;
    background-color: #eace8e;
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 30px;
    display: inline-block;
    margin: auto;
    &:hover{
      background: #1db8d3;
    }
  }
}

.section{
  padding: 100px 0 70px;
}
.about{
  display: flex;
  justify-content: center;
  &-item{
    text-align: center;
    margin: 0 50px 30px;
    &__number{
      color: #32b3dd;
      font-size: 55px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__text{
      color: #222222;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.partner{
  margin-bottom: 40px;
  &-item{
    margin: 0 15px;
    display: flex;
    justify-content: center;
    &__img{
      border: 3px solid #d3f4fb;
      background-color: #ffffff;
      width: 165px;
      height: 165px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      img {
        display: block;
        margin: auto;
        max-height: 100%;
      }
    }
  }
  &-block{
    position: relative;
    .slick-nav__prev,
    .slick-nav__next{
      background: none;
      margin-top: -30px;
      color: #eace8e;
      left: -60px;
    }
    .slick-nav__next{
      left: auto;
      right: -60px;
      transform: scaleX(-1);
    }
  }
}



footer{
  background: url('../img/footer-bg.jpg') bottom center no-repeat;
  background-size: cover;
  padding: 40px 0;
}

.footer{
  display: flex;
  justify-content: space-between;
  &__col{}
}
.logo-footer{
  display: block;
  height: 35px;
  margin-bottom: 40px;
  img{
    display: block;
    max-height: 100%;
  }
}
.credo-footer{
  &__title{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
  &__content{
    color: #ffffff;
    font-size: 14px;
    line-height: 130%;
  }
}
.footer__social{
  margin-bottom: 35px;
  .socials__link{
    background: #fff;
    &:hover{
      background: rgba(#fff, 0.5);
    }
  }
}
.menu-footer{
  margin-top: 75px;
  &__title{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
  &__content{
    margin: 0;
    padding: 0;
  }
  &__li{
    display: block;
    margin-top: 15px;
  }
  &__link{
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}
.contacts-footer{
  &__title{
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
  &__content{
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    margin-top: 15px;
  }
  &__mail,
  &__phone{
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
    margin-top: 15px;
    display: inline-block;
    &:hover{
      text-decoration: underline;
    }
  }
  &__mail{
    font-weight: 400;
  }
}

.inner{
  padding-top: 75px;
}
.breadcrumbs{
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  &__li{
    display: block;
    &:before{
      content: '-';
      color: #222222;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      margin: 0 5px;
    }
    &:first-child:before{
      display: none;
    }
  }
  &__link{
    color: #222222;
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
      color: #1db8d3;
    }
  }
  &__span{
    color: #222222;
    font-size: 14px;
    line-height: 22px;
  }
}

.h1{
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 35px 0 25px;
}
.tabs-titles{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.tabs-title{
  border-radius: 20px;
  border: 1px solid #d3f4fb;
  background-color: #ffffff;
  padding: 10px 20px 10px;
  color: #222222;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  margin-right: 20px;
  margin-bottom: 20px;
  &.active,
  &:hover{
    background: #d3f4fb;
  }
}
.tab{
  display: none;
  &.active{
    display: block;
  }
}

.scheme{
  &__title{
    color: #32b3dd;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
  &__row{
    display: flex;
    justify-content: center;
    &--first{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: auto;
        right: auto;
        margin: 0 auto;
        background: #1db8d3;
        height: 2px;
        width: 75.2%;
      }
    }
  }
}
.pers{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21%;
  margin: 0 2%;
  margin-bottom: 20px;
  &:before{
    content: '';
    display: block;
    width: 24px;
    height: 30px;
    background: url('../img/arrow-bottom.svg') center center no-repeat;
    margin-bottom: 20px;
  }
  &--first{
    &:after{
      content: '';
      display: block;
      width: 24px;
      height: 30px;
      background: url('../img/arrow-bottom.svg') center center no-repeat;
      margin-top: 20px;
    }
  }
  &--mini{
    .pers__post{
      width: 100%;
      color: #000;
      text-align: left;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
  &__ava{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 190px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 30px;
    img{
      display: block;
    }
  }
  &__post{
    color: #32b3dd;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin-bottom: auto;
  }
}

.about-b{
  margin-top: 40px;
  margin-bottom: 50px;
  &__item{
    border-top: 1px solid #d3f4fb;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    &:first-child{
      margin-top: 0;
      border-top: 0;
    }
  }
  &__title{
    width: 300px;
    flex-shrink: 0;
    color: #222222;
    font-size: 14px;
    font-weight: 700;
  }
}

.firm-style{
  margin-bottom: 50px;
}
.item-style{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d3f4fb;
  padding-top: 20px;
  padding-bottom: 20px;
  &__content{
    display: flex;
    width: 50%;
    margin-right: 20px;
  }
  &__info{
    text-decoration: none;
    &:hover{
      .item-style__link{
        text-decoration: underline;
      }
    }
  }
  &__icon{
    width: 30px;
    margin-right: 10px;
    color: #32b3dd;
    .icon{
      display: block;
      width: 30px;
      height: 30px;
      fill: currentColor;
    }
  }
  &__link{
    color: #32b3dd;
    font-size: 14px;
  }
  &__size{
    font-size: 14px;
    color: #666666;
  }
  &__text{
    color: #222222;
    font-size: 14px;
    font-weight: 400;
  }
}

.typograph{
  margin-top: 20px;
  margin-bottom: 50px;
  a{
    color: #32b3dd;
    font-size: 14px;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  h3 {
    color: #222222;
    font-size: 14px;
    font-weight: 700;
  }
  p {
    color: #222222;
    font-size: 14px;
    line-height: 130%;
  }
  &--bank{
    h3{
      margin-bottom: 5px;
    }
    p{
      margin-top: 5px;
    }
  }
  &__cols{
    display: flex;
    margin: 0 -15px 30px;
  }
  &__col{
    width: 50%;
    margin: 0 15px;
    &--border-3{
      width: 33%;
      border-radius: 20px;
      border: 1px solid #d3f4fb;
      background-color: #ffffff;
      padding: 30px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
  &__flex{
    display: flex;
    margin: 35px 0 30px;
    .cash-news{
      margin-right: 30px;
    }
  }
}
.fond{
  align-items: center;
  img{
    display: block;
    margin-right: 30px;
  }
}
.photo-video{
  margin: 30px 0 70px;
  &__content{}
  &__link{
    display: block;
    img{
      width: 100%;
      display: block;
    }
  }
}
.slider-mini{
  position: relative;
  .slick-nav__prev,
  .slick-nav__next{
    background: none;
    margin-top: -60px;
    color: #eace8e;
    left: auto;
    right: 45px;
    top: -20px;
    &:hover{
      color: #1db8d3;
    }
  }
  .slick-nav__next{
    left: auto;
    right: -15px;
    transform: scaleX(-1);
  }
  .slick-slide {
    padding: 0 15px;
  }

  .slick-list {
    margin: 0 -15px;
  }
}

.project-mini{
  &__title{
    color: #222;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  &__content{
    max-width: 250px;
  }
}
.contacts-b{
  display: flex;
  margin-bottom: 50px;
  &__col{
    width: 50%;
    &:first-child{
      width: calc(50% - 30px);
      margin-right: 30px;
    }
  }
  h2{
    margin-top: 0;
    margin-bottom: 50px;
  }
}
.form{
  &__group{
    input,
    textarea{
      width: 100%;
      display: block;
      font-family: 'Montserrat', sans-serif;
      color: #222222;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #32b3dd;
      outline: none;
      resize: none;
      padding: 10px 0;
      margin-bottom: 10px;
    }
    textarea{
      height: 140px;
    }
  }
  &__robot{
    margin-top: 27px;
    margin-bottom: 20px;
    img{
      max-width: 100%;
    }
  }
  &__button{
    border-radius: 20px;
    background-color: #eace8e;
    border: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 13px 40px;
    cursor: pointer;
    &:hover{
      background: #1db8d3;
    }
  }
}
.news-block--inner{
  .news{
    width: calc(25% - 30px);
  }
}

.pagination{
  display: flex;
  margin-bottom: 40px;
  &__link{
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(#32b3dd, 0.25);
    margin-right: 10px;
    text-align: center;
    line-height: 40px;
    color: #32b3dd;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    &:hover,
    &.active{
      background: #1db8d3;
      color: #fff;
    }
  }
}
.news-block--projects{
  .news{
    width: calc(20% - 30px);
  }
}

.cash-news{
  display: flex;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 0px;
  &__icon{
    width: 50px;
    height: 50px;
    color: #32b3dd;
    margin-right: 20px;
    .icon{
      display: block;
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
  &__title{
    color: #222;
    font-size: 14px;
    margin-bottom: 5px;
  }
  &__price{
    color: #32b3dd;
    font-size: 25px;
    font-weight: 700;
    b{
      color: #222;
      margin-left: 5px;
    }
  }
}

.maps{
  margin-bottom: 60px;
  img{
    max-width: 100%;
  }
  h2{
    color: #222222;
    font-size: 25px;
    font-weight: 700;
  }
}
.map-row{
  display: flex;
  margin-top: 50px;
}

.coop{
  display: flex;
  margin-top: 35px;
  margin-bottom: 50px;
  &__left{
    width: 265px;
    flex-shrink: 0;
    margin-right: 35px;
  }
  h1{
    margin-top: 0;
  }
}
.side-menu{
  padding: 0;
  margin: 0;
  border-radius: 20px;
  border: 1px solid #d3f4fb;
  background-color: #ffffff;
  overflow: hidden;
  &__li{
    display: block;
    border-bottom: 1px solid #d3f4fb;
    &:last-child{
      border-bottom: none;
    }
  }
  &__link{
    display: block;
    text-decoration: none;
    padding: 15px 30px;
    color: #222222;
    font-size: 14px;
    &:hover,
    &.active{
      background-color: #f1fbfd;
    }
    &.active{
      font-weight: bold;
    }
  }
}
.gallery{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 50px;
  &__item{
    border-radius: 20px;
    border: 1px solid #d3f4fb;
    background-color: #ffffff;
    margin: 0 15px 20px;
    width: 260px;
    padding: 13px;
  }
  &__link{
    background: url('../img/gallery.jpg') center center no-repeat;
    width: 240px;
    height: 340px;
    padding: 25px;
    display: block;
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.with-icon{
  display: flex;
  margin-bottom: 10px;
  &__icon{
    min-width: 25px;
    margin-right: 5px;
    flex-shrink: 0;
  }
  &__text{
    color: #222222;
    font-size: 14px;
    font-weight: 400;
  }
}

@import 'responsive';