/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    outline: none;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow{
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
  background-color: rgba(#ffffff, 0.5);
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  font-size: 0;
  z-index: 1;
  margin-top: -60px;
  cursor: pointer;
  display: flex;
  .icon{
    width: 24px;
    height: 24px;
    fill: currentColor;
    margin: auto;
  }
}
.slick-next{
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

.slick-dots{
  position: absolute;
  top: calc(100% + 25px);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  li{
    display: block;
    width: 10px;
    height: 10px;
    font-size: 0;
    background-color: #65d5e3;
    border-radius: 50%;
    margin: 5px 10px 5px;
    cursor: pointer;
    &.slick-active{
      background-color: #eace8e;
    }
    button{
      display: none;
    }
  }
}